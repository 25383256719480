import * as React from 'react';
import { isMobile } from 'react-device-detect';
import useResizeObserver from 'use-resize-observer';

const sources: {
  desktop: Record<string, string>;
  mobile: Record<string, string>;
} = {
  desktop: {
    1080: 'https://player.vimeo.com/progressive_redirect/playback/912172469/rendition/1080p/file.mp4?loc=external&log_user=0&signature=246e2e1ff23ef73b9c1ff64e23d44f7617e913e689febb0aca9d0a300c6c45eb',
    720: 'https://player.vimeo.com/progressive_redirect/playback/912172469/rendition/720p/file.mp4?loc=external&log_user=0&signature=4bdfcb3b94af8b37f95ce17be70e11a7bb41d335089d1b7c6deedfc3973b7319',
    540: 'https://player.vimeo.com/progressive_redirect/playback/912172469/rendition/540p/file.mp4?loc=external&log_user=0&signature=6997f89656e18642b8af6c8c85abbe5a0cebf806dad6a2ca431e319488d7630d'
  },
  mobile: {
    1080: 'https://player.vimeo.com/progressive_redirect/playback/912172452/rendition/1080p/file.mp4?loc=external&log_user=0&signature=d1a5b73eeb806fd12bcc148e647c50875f1ee755a65e3476c478eeeb19a599e0',
    720: 'https://player.vimeo.com/progressive_redirect/playback/912172452/rendition/720p/file.mp4?loc=external&log_user=0&signature=1c11d333fdb918496ad1dd6b6a8d60852b3cf81dfae19fb6b02e10f89620677e',
    540: 'https://player.vimeo.com/progressive_redirect/playback/912172452/rendition/540p/file.mp4?loc=external&log_user=0&signature=235b947e3ac063972ae7e4ef3f7479426be35be8d5628bac384a71f4c8d617c8',
    360: 'https://player.vimeo.com/progressive_redirect/playback/912172452/rendition/360p/file.mp4?loc=external&log_user=0&signature=bd97dabddc484dded2c4913beedb158c87913401f21cb0018bfc2606a9de3ab7'
  }
};

const posterImg = isMobile
  ? 'https://tf-web-assets.imgix.net/images/studio1o/mobile-poster.png?auto=format'
  : 'https://tf-web-assets.imgix.net/images/studio1o/desktop-poster.png?auto=format';

export function VideoBg() {
  const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();
  const [isMetadataLoaded, setIsMetadataLoaded] = React.useState(false);

  const handleLoadedMetadata = (
    e: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    setIsMetadataLoaded(true);
    const target: HTMLVideoElement = e.currentTarget;
    target.play();
  };

  const screenSources = isMobile ? sources.mobile : sources.desktop;

  // check the screen size and set the best video src size for best user experience
  // grabbed from VideoLoop - studio-octopus
  const sourceEntries = React.useMemo(() => {
    const entries = Object.entries(screenSources)
      .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
      .map((e, i) => [parseInt(e[0]), e[1]]);
    entries[entries.length - 1][0] = Infinity;
    return entries;
  }, [screenSources]);

  // grabbed from VideoLoop - studio-octopus
  const selectedSource = React.useMemo(() => {
    const bestSizeOption = sourceEntries.filter((entry) => {
      return width < entry[0];
    });
    return bestSizeOption[0][1];
  }, [sourceEntries, width]);

  return (
    <div
      ref={ref}
      className="absolute inset-0 h-full w-full bg-black opacity-30"
    >
      <video
        className="h-full w-full transition-opacity duration-500 ease-out-quart object-cover object-center"
        onLoadedMetadata={handleLoadedMetadata}
        poster={posterImg}
        preload="metadata"
        src={selectedSource as string}
        style={{ opacity: isMetadataLoaded ? 1 : 0 }}
        loop
        muted
        playsInline
      />
    </div>
  );
}
