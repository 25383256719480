import {
  bootstrapCameraKit,

  CameraKitSession,

  createMediaStreamSource,
  Transform2D
} from '@snap/camera-kit';

import React, { useEffect, useRef, useState } from 'react';

import './styles.css';
import MobileIntro from './MobileIntro';
import RecordButton from './RecordButton';
import VideoPlayer from './VideoPlayer';
import ShareButton from './ShareButton';
import { IconButton } from './IconButton';
import { PoweredBySnapchat } from './PoweredBySnapchat';

import { ga_event } from './Analytics';
import { useDeviceOrientation } from './hooks/useDeviceOrientation';
import LandscapeFallback from './LandscapeFallback';

import { useMediaRecorder, type CameraType } from './hooks/useMediaRecorder';
import { SlideUpDrawer } from './SlideUpDrawer';

import * as Config from './Config';

const Snap: React.FC = () => {
  const { isPortrait } = useDeviceOrientation();
  const canvasRef = useRef<HTMLCanvasElement>(null!);
  const sessionRef = useRef<CameraKitSession | null>(null);
  const streamRef = useRef<MediaStream>(null!);

  const [disableInstructions, setDisableInstructions] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  /** for preventing double initialization call in development due to react.strict mode useEffect double call on mount */
  const isInitializing = useRef(false);

  /**
   * @note managing camera selected 2 ways, bc I could not simplify to use only state quickly.
   *  - isBackFacingRef -> used in updateCamera to update with the latest value
   *  - setting selectedCamera state to provide useMediaRecorder with selected camera, since refs do not cause re-renders
   * @todo completely replace isBackFacingRef with selectedCamera state to manage the camera. will require `updateCamera` to be refactored.
   */
  const isBackFacingRef = useRef(false);
  const [selectedCamera, setSelectedCamera] = useState<CameraType>(
    isBackFacingRef.current ? 'back' : 'front'
  );
  // const debugRef = useRef<String>('');

  const mediaRecorder = useMediaRecorder({
    canvasRef,
    camera: selectedCamera
  });

  const updateCamera = async () => {
    if (!sessionRef.current || !canvasRef.current) return;

    if (streamRef.current) {
      sessionRef.current.pause();
      streamRef.current.getVideoTracks()[0].stop();
    }

    /** set here to force useMediaRecorder to re-initialize with correct camera */
    setSelectedCamera(isBackFacingRef.current ? 'back' : 'front');

    streamRef.current = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: isBackFacingRef.current ? 'environment' : 'user'
      },
      audio: true
    });

    const source = createMediaStreamSource(streamRef.current, {
      // NOTE: This is important for world facing experiences
      cameraType: isBackFacingRef.current ? 'back' : 'front'
    });

    await sessionRef.current.setSource(source);

    await source.setRenderSize(
      canvasRef.current?.offsetWidth * 2,
      canvasRef.current?.offsetHeight * 2
    );

    if (!isBackFacingRef.current) {
      source.setTransform(Transform2D.MirrorX);
    }

    sessionRef.current.play('live');
  };

  const toggleBackCamera = () => {
    isBackFacingRef.current = !isBackFacingRef.current;
    updateCamera();
  };

  const clearRecordedMedia = React.useCallback(() => {
    mediaRecorder.clearRecording();
  }, [mediaRecorder]);

  const clickShareButton = async () => {
    const { recording } = mediaRecorder;
    if (!recording) {
      console.warn('oops, no recording to share');
      return;
    }

    const fileFormatMap: Record<typeof recording.type, string> = {
      image: 'png',
      video: 'mp4'
    };

    const fileFormat = fileFormatMap[recording.type];
    
    const file = new File([recording.blob], `Studio1o.${fileFormat}`, {
      type: `${recording.type}/${fileFormat}`,
      lastModified: new Date().getTime()
    });

    const cameraType = isBackFacingRef ? 'back' : 'front';

    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      navigator
        .share({
          files: [file] //or using arrays refer to the end comment
        })
        .then(() => {
          if (process.env.REACT_APP_ENV === 'development')
          {
            console.log("debugging share event");
          }
          else
          {
            ga_event(`shared-${recording.type}-${cameraType}`, {});
          }

          clearRecordedMedia();
        })
        .catch((error) => console.log('Sharing failed', error));
    } else {
      console.warn(`Your system doesn't support sharing files.`);
    }
  };

  const handleRecording = (isRecording: boolean) => {
    /** we can assume user knows how to use record button now.
     * disable instructions
     */
    if (!disableInstructions) {
      setDisableInstructions(true);
    }

    if (isRecording) {
      mediaRecorder.start();
    } else {
      mediaRecorder.stop();
    }
  };

  const handleShareAppLink = () => {
    /**
     * use the hostname and add new campaign
     */
    // const url = `${window.location.hostname}?campaign=share`;
    const url = `?campaign=share`;

    if (navigator.canShare && navigator.canShare({ url })) {
      navigator
        .share({
          url,
          title: `{Config.TITLE}`
        })
        .then(() => {
          if (process.env.REACT_APP_ENV === 'development')
          {
            console.log("debugging shared url");
          }
          else
          {
            ga_event(`shared-url`, {});
          }
        })
        .catch((error) => console.log('Sharing failed', error));
    } else {
      console.warn(`Your system doesn't support sharing urls.`);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.warn('tried to initialize camerakit,but no canvas');
      return;
    }

    const initializeCameraKit = async () => {
      isInitializing.current = true;
      console.log('initializeCameraKit');

      try {
        const cameraKit = await bootstrapCameraKit({
          apiToken: process.env.REACT_APP_SNAP_CAMERA_KIT_TOKEN!
          // logger: 'console'
        });

        const session = await cameraKit.createSession({
          liveRenderTarget: canvas
        });

        const lens = await cameraKit.lensRepository.loadLens(
          Config.LENS_ID,
          Config.LENS_GROUP_ID
        );
        await session.applyLens(lens, { showLogo: "true"});
        sessionRef.current = session;
        await updateCamera();
        setIsInitialized(true);
      } catch (error: unknown) {
        console.error(error);
        return null;
      } finally {
        isInitializing.current = false;
      }
    };

    if (isInitializing.current === false) {
      /** prevent double mount useEffect error in development from trying to initialize 2x */
      initializeCameraKit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitializing.current) {
      updateCamera();
    }
  }, [isPortrait]);

  const menuContent = (
    <>
      {!mediaRecorder.recording && (
        <div className="grid grid-cols-[1fr,2fr,1fr] gap-6 items-center justify-items-center px-10 py-4 sticky bottom-0 ">
          <IconButton
            disabled={mediaRecorder.isRecording}
            icon="sync"
            onClick={toggleBackCamera}
          />
          <RecordButton
            disableInstructions={disableInstructions}
            toggleRecording={handleRecording}
          />
          <IconButton icon="share" onClick={handleShareAppLink} />
        </div>
      )}

      {!!mediaRecorder.recording && (
        <div className="absolute h-full w-full">
          <SlideUpDrawer isPhoto={mediaRecorder.recording.type === 'image'}>
            {mediaRecorder.recording.type === 'image' && (
              <img
                className="h-full w-full"
                height={canvasRef.current?.offsetWidth}
                width={canvasRef.current?.offsetHeight}
                src={URL.createObjectURL(mediaRecorder.recording.blob)}
                alt={Config.TITLE}
              />
            )}
            {mediaRecorder.recording.type === 'video' && (
              <VideoPlayer videoBlob={mediaRecorder.recording.blob} />
            )}
            <ShareButton
              title={mediaRecorder.recording?.type}
              clickShareButton={clickShareButton}
            />
            <div className="absolute left-5 top-5">
              <IconButton icon="back" onClick={clearRecordedMedia} />
            </div>
          </SlideUpDrawer>
        </div>
      )}
    </>
  );

  return (
    <div className="fixed inset-0 h-full w-full grid">
      <div className="relative h-full w-full">
        {/* <p>{debugRef.current}</p> */}
        <canvas
          id="live-canvas"
          className="absolute inset-0 h-full w-full touch-none z-0 overflow-hidden"
          ref={canvasRef}
        />

        {!isPortrait && (
          <div className="fixed inset-0 bg-black/80">
            <LandscapeFallback instructions="Landscape mode is not supported, yet. " />
          </div>
        )}

        {isPortrait && isInitialized && (
          <>
            <div className="flex flex-col justify-between h-full w-full fixed inset-0">
              <div />
              {menuContent}
            </div>
          </>
        )}

        {isPortrait && !isInitialized && (
          <MobileIntro
            title={Config.TITLE}
            instructions={Config.INSTRUCTIONS}
          />
        )}
      </div>
      {isInitialized && !mediaRecorder.recording && <PoweredBySnapchat />}
    </div>
  );
};

export default Snap;
