// DesktopFallbackQRCode.tsx

import * as React from 'react';
import './styles.css';

const DesktopFallbackQRCode: React.FC = () => {
  return (
    <div className="w-max mx-auto overflow-clip rounded-lg">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="128px" height="128px" viewBox="0 0 2257 2257" enableBackground="new 0 0 2257 2257" xmlSpace="preserve">
<rect x="0" y="0" width="2257" height="2257" fill="rgb(255,255,255)" /><g transform="translate(122,122)"><g transform="translate(488,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,0) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,61) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,61) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,61) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,61) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,61) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,61) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,122) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,122) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,122) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,122) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,122) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,122) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,122) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,122) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,183) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,244) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,244) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,244) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,244) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,244) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,244) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,244) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,244) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,244) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,305) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,305) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,305) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,305) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,305) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,305) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,305) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,305) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,366) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,366) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,366) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,366) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,366) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,366) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,366) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,366) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,366) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,427) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,427) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,427) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,427) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,427) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,427) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,427) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(122,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(183,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(305,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(366,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(427,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,488) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(183,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(244,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1830,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,549) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(366,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(427,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1525,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,610) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(61,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(122,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(183,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(305,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,671) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(122,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(244,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(366,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1525,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,732) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(122,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(183,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(427,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,793) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(61,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(183,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(366,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,854) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(183,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(427,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1525,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1830,915) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(183,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(244,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(366,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1525,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1830,976) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(427,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,1037) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(61,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(183,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(305,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(366,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1830,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,1098) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(122,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(427,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1525,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,1159) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(61,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(122,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(305,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(366,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1830,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,1220) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,1281) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(122,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(183,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(305,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(366,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(427,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1830,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,1342) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(61,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(244,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(427,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,1403) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(366,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(427,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1525,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,1464) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,1525) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1586) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1830,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1891,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,1647) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(671,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1525,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1830,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,1708) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1769) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1769) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,1769) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,1769) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,1769) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,1769) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,1769) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,1769) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,1769) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1830) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,1830) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,1830) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1342,1830) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1830) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1525,1830) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,1830) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,1830) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1769,1830) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(549,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(854,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1220,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1464,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1586,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1647,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1952,1891) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(488,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(610,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(732,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(793,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(915,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(976,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1037,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1098,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1159,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1281,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1403,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1708,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(1830,1952) scale(0.122,0.122)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,0) scale(4.27, 4.27)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<path style={{ fill: "none" }} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(1586,0) scale(4.27, 4.27)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<path style={{ fill: "none" }} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(0,1586) scale(4.27, 4.27)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<path style={{ fill: "none" }} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(122,122) scale(1.83, 1.83)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<g id="XMLID_1_">
		<g>
			<path d="M27.351,100c-15.09,0-27.365-12.032-27.365-26.808V26.794c0-4.616,1.2-8.96,3.301-12.761
				c2.029-3.658,4.901-6.802,8.36-9.174C16.09,1.801,21.506,0,27.336,0h45.327c15.076,0,27.351,12.018,27.351,26.793v46.398
				c0,14.775-12.274,26.808-27.351,26.808H50H27.351z"/>
		</g>
		<g>
		</g>
	</g>
</g>
</g></g><g transform="translate(1708,122) scale(1.83, 1.83)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<g id="XMLID_1_">
		<g>
			<path d="M27.351,100c-15.09,0-27.365-12.032-27.365-26.808V26.794c0-4.616,1.2-8.96,3.301-12.761
				c2.029-3.658,4.901-6.802,8.36-9.174C16.09,1.801,21.506,0,27.336,0h45.327c15.076,0,27.351,12.018,27.351,26.793v46.398
				c0,14.775-12.274,26.808-27.351,26.808H50H27.351z"/>
		</g>
		<g>
		</g>
	</g>
</g>
</g></g><g transform="translate(122,1708) scale(1.83, 1.83)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<g id="XMLID_1_">
		<g>
			<path d="M27.351,100c-15.09,0-27.365-12.032-27.365-26.808V26.794c0-4.616,1.2-8.96,3.301-12.761
				c2.029-3.658,4.901-6.802,8.36-9.174C16.09,1.801,21.506,0,27.336,0h45.327c15.076,0,27.351,12.018,27.351,26.793v46.398
				c0,14.775-12.274,26.808-27.351,26.808H50H27.351z"/>
		</g>
		<g>
		</g>
	</g>
</g>
</g></g></g></svg>
	</div>

  );
};

export default DesktopFallbackQRCode;
