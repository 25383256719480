import * as React from 'react';
import Snap from './Snap';
import { isMobile } from 'react-device-detect';
import DesktopFallback from './DesktopFallback';
import { useSentry } from './third-party-services/useSentry';
import { ga_event } from './Analytics';
import * as Config from './Config';

function reportCampaign() {
  // getting the campaing parameter
  // Parse the query parameters from the URL
  const params = new URLSearchParams(window.location.search);

  const campaign = params.get('utm_campaign');

  if (campaign) {

    if (process.env.REACT_APP_ENV === 'development')
    {
      console.log("debugging");
    }
    else
    {
      ga_event(`campaign-${campaign}`, {});
    }
    
  }
}

const App: React.FC = () => {
  useSentry();
  React.useEffect(() => {
    reportCampaign();
  }, []);

  return (
    <div className="relative h-[100dvh] w-full bg-black">
      {!isMobile && (
        <DesktopFallback
          title={Config.TITLE}
          instructions={Config.INSTRUCTIONS}
        />
      )}
      {isMobile && <Snap />}
    </div>
  );
};

export default App;